<template>
<div>
  <b-container fluid id="app">
    
    <b-container id="intro">
      <b-navbar toggleable="md" class="nav-bar">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item href="#about-me" class="nav-item">About</b-nav-item>
            <b-nav-item href="#experience" class="nav-item">Experience</b-nav-item>
            <b-nav-item href="#portfolio" class="nav-item">Portfolio</b-nav-item>
            <b-nav-item href="https://www.youtube.com/channel/UCBN7ajw639RuJyjKR8tAuqQ" target="_blank" class="nav-item">Videos</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
              <a href="./Resume.pdf" target="_blank"><b-button size="sm" class="nav-button">Resume</b-button></a>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>


      <b-row class="title">
        <b-col cols="md-8"><h1>HEY, IT'S ADA VU.</h1></b-col>
        <b-col cols="md-8"><h1>NICE TO MEET YA!</h1></b-col>
      </b-row>

      <button class="h2 mb-0 arrow-down">
        <a href="#about-me"><b-icon-arrow-down></b-icon-arrow-down></a>
      </button>


    </b-container>

    <b-container id="about-me">
      <b-row>
        <b-col cols="lg-5">
          <img class="shadow-lg mb-5 profile-pic" src="@/assets/profilePic.jpg" fluid alt="Responsive image">
        </b-col>
        
        <b-col cols="lg-7" class="paragraph">
          <b-row>
            <b-col cols="1" style="margin-right: 10px;">
              <img class="hello-kitty" src='@/assets/helloKitty.svg'/>
            </b-col>
            <b-col cols="10">
              <h2>ABOUT ME</h2>
            </b-col>
          </b-row>
          <h6 class="paragraph">Hello! I am a Computer Science student at the University of Waterloo with an affinity for game engineering and UI development work. At Riot Games, I gained game engineering experience which I greatly enjoyed and hope to pursue game engineering as a career down the line. I am also interested in UI development and have multiple experiences with frontend/UI development in React and Javascript. Throughout various other internships, I also gained full stack web development experience, including API development in Node.js. I look forward to taking on as many opportunities to quickly grow my skill set and make an impact to the work environment! </h6>
          <h6 class="paragraph">Other than school, co-op and other projects, I really like to be active so I love to go biking and rollerblading by the lake. I also like to game as a way to relieve stress. Hit me up if you like Valorant too haha! Side note: I also like learning languages and cultures! And Hello Kitty is my absolute favourite! :))</h6>
          <b-row>
            <b-col cols="lg-2">
              <a href="https://www.facebook.com/ada.vuuu/" target="_blank"><b-img class="social-media" src="@/assets/facebook.svg"></b-img></a>
            </b-col>
            <b-col cols="lg-2">
              <a href="https://www.instagram.com/ada.vu/" target="_blank"><b-img class="social-media" src="@/assets/instagram.svg"></b-img></a>
            </b-col>
            <b-col cols="lg-2">
              <a href="https://www.linkedin.com/in/ada-vu/" target="_blank"><b-img class="social-media" src="@/assets/linkedin.svg"></b-img></a>
            </b-col>
            <b-col cols="lg-2">
              <a href="https://github.com/ada-vu" target="_blank"><b-img class="social-media" src="@/assets/github.svg"></b-img></a>
            </b-col>
          </b-row>
          
        </b-col>
      </b-row>

    </b-container>

    <b-container id="experience">
      <b-row>
        <b-col cols="lg-5">
          <div class="sections">
            <h3>EDUCATION</h3>
            <div class="title-accent"></div>
            <div class="section-item">
              <h6 class="title-skill">Bachelor of Computing and Financial Management</h6>
              <h6 class="skill">University of Waterloo 2019-2024</h6>
            </div>
          </div>
          <div class="sections">
            <h3>PROFICIENCIES</h3>
            <div class="title-accent"></div>
            <div class="section-item">
              <h6 class="title-skill">Tool Proficiency</h6>
              <h6 class="skill">Git, GitHub, Perforce, Jira, VS Code, Xcode</h6>
              <h6 class="title-skill">Coding Languages</h6>
              <pre><h6 class="skill">C++, C, Game Engineering, 
JavaScript, TypeScript, Swift, Go, Kotlin</h6></pre>
              <h6 class="title-skill">Languages</h6>
              <h6 class="skill">English, Vietnamese, Korean(in learning) Chinese(in learning)</h6>
            </div>
          </div>
        </b-col>

        <b-col cols="lg-7">
          <h2>WORK EXPERIENCE</h2>
          <br>
          <Experience />
        </b-col>
      </b-row>
    </b-container>

    <b-container id="portfolio">
      <b-row>
        <center><h2 style="margin-bottom: 40px;">PORTFOLIO</h2></center>
      </b-row>

      <div>
        <b-card no-body class="portfolio-card overflow-hidden shadow-lg">
          <b-row no-gutters>
            <b-col md="7">
              <b-card-img :src="require('../assets/CUPID-BOT.png')" class="project-image"  alt="Image"></b-card-img>
            </b-col>
            <b-col md="5">
              <b-card-body class="project-body" title="Cupid Discord Bot" sub-title="2021">
                <b-card-text>
                  Created a discord bot with Discord.js to allow users of a discord server to be a cupid and couple up friends (or strangers).                       
                </b-card-text>
                <b-card-text>
                  <b-card-text class="toolkit"> Toolkit </b-card-text>Discord.js, Tenor API
                </b-card-text>
                <a href="https://www.youtube.com/watch?v=6-HaiewAXH4&ab_channel=AdaVu" target="_blank"><b-button size="sm" class="project-button" type="submit">Project Demo</b-button></a>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <div>
        <b-card no-body class="portfolio-card overflow-hidden shadow-lg">
          <b-row no-gutters>
            <b-col md="7">
              <b-card-img class="project-image" :src="require('../assets/blossonRUhack.jpeg')" alt="Image"/>
            </b-col>
            <b-col md="5">
              <b-card-body class="project-body" title="Blossom - RUHacks" sub-title="2021">
                <b-card-text>
                  Our team created a web application for students to explore side projects and find team members to collaborate with.
                </b-card-text>
                <b-card-text>
                  <b-card-text class="toolkit"> Toolkit </b-card-text>Django, Bootstrap, HTML/CSS
                </b-card-text>
                <a href="https://www.youtube.com/watch?v=5wTDNEUq6aU&t=150s&ab_channel=AdaVu" target="_blank"><b-button size="sm" class="project-button" type="submit">Project Demo</b-button></a>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>
      
      <div>
        <b-card no-body class="portfolio-card overflow-hidden shadow-lg">
          <b-row no-gutters>
            <b-col md="7">
              <b-card-img class="project-image" :src="require('../assets/speedy.png')" alt="Image"/>
            </b-col>
            <b-col md="5">
              <b-card-body class="project-body" title="Speedy - iOS App" sub-title="2018">
                <b-card-text>
                  Speedy is a game where players race against time to eliminate all the colours displayed on a given grid.
                </b-card-text>
                <b-card-text>
                  <b-card-text class="toolkit"> Toolkit </b-card-text>Swift, Xcode, iOS
                </b-card-text>
                <a href="https://www.youtube.com/watch?v=vDSm5Cgmg3I&ab_channel=AdaVu" target="_blank"><b-button size="sm" class="project-button" type="submit">Project Demo</b-button></a>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <div>
        <b-card no-body class="portfolio-card overflow-hidden shadow-lg">
          <b-row no-gutters>
            <b-col md="7">
              <b-card-img class="project-image" :src="require('../assets/Memory-Game.png')" alt="Image"/>
            </b-col>
            <b-col md="5">
              <b-card-body class="project-body" title="Memory Game - iOS App" sub-title="2018">
                <b-card-text>
                  Memory game is to test your memory and get players to match all the cards while they are faced down.
                </b-card-text>
                <b-card-text>
                  <b-card-text class="toolkit"> Toolkit </b-card-text>Swift, Xcode, iOS
                </b-card-text>
                <a href="https://www.youtube.com/watch?v=AFdUOwXoC6Q&ab_channel=AdaVu" target="_blank"><b-button size="sm" class="project-button" type="submit">Project Demo</b-button></a>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>

    </b-container>

  </b-container>

  <div class="footer"></div>  
</div>
</template>

<script>
import { BIconArrowDown } from 'bootstrap-vue'
import Experience from './Experience.vue'
  export default {
    name: 'HelloWorld',
    components: {
      BIconArrowDown,
      Experience
    },
  }
  
</script>

<style lang="scss" scoped>
h1 {
  font-size: 80px;
  font-family: Ageo-ExtraBold;
}
h2 {
  font-size: 40px;
  font-family: Ageo-ExtraBold;
  color: #323345;
}
h3 {
  font-size: 30px;
  font-family: Ageo-ExtraBold;
  color: #323345;
}
h6 {
  font-size: 21px;
  font-family: Ageo-Regular;
  color: #323345;
}
#app {
  background-image: url('~@/assets/1440p.png');
  background-size: 100%;
  height: 100%;
  width: 100%;
}
.nav-bar {
  padding-top: 70px;
}
.nav-item {
  padding-right: 50px;
  font-family: Ageo-SemiBold;
  font-size: 18px;
}
.nav-button {
  border-radius: 30px;
  width: 180px;
  height: 45px;
  background-color: #323345;
  font-family: Ageo-Bold;
  font-size: 20px;
}
.title {
  margin-top: 100px;
  color: #323345;
}
.arrow-down {
  margin-top: 40px;
}
#about-me {
  margin-top: 120px;
  padding-top: 50px;
}
.profile-pic {
  border-radius:50% 50% 50% 50%;  
  width:400px;
  height:400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}
.hello-kitty {
  height: 40px;
  width: 55px;
}
.paragraph {
  margin-top: 50px;
  margin-bottom: 70px;
  line-height: 1.8;
}
.social-media {
  height: 40px;
  width: 40px;
}
.sections {
  margin-bottom: 80px;
  
  .section-item {
    padding-top: 10px;
  }
}
.title-accent {
  position: absolute;
  width: 83px;
  height: 3px;
  margin-bottom: 10px;

  background: linear-gradient(90deg, #A07CF0 7.84%, #CAA5F0 107.84%);
  border-radius: 5px;
}
.title-skill {
  line-height: 1.8;
  margin-top: 30px;
  margin-bottom: 10px;
}
.skill {
  line-height: 1.8;
  margin-bottom: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.work-experience-card {
  border-radius: 40px;
}
.company-button {
  border-radius: 30px;
  width: 200px;
  height: 45px;
  background-color: #323345;
  font-family: Ageo-Bold;
  font-size: 20px;
}
.card-text {
  line-height: 1.8;
  margin-top: 30px;
  margin-bottom: 30px;
}
#experience {
  margin-top: 50px;
  padding-top: 100px;
}
#portfolio {
  padding-top: 50px;
  margin-top: 100px;
  padding-bottom: 100px;
  display: block;
}
.portfolio-card {
  height: 450px;
  border-radius: 40px;
  margin-bottom: 40px;
}
.project-image {
  max-width: 750px;
  height: 450px;
}
.project-body {
  margin-top: 35px;
  margin-bottom: 35px;
}
.toolkit {
  color: rgb(134, 134, 134);
}
.project-button {
  border-radius: 30px;
  width: 170px;
  height: 45px;
  background-color: #323345;
  font-family: Ageo-Bold;
  font-size: 20px;
  margin-right: 15px;
}
.footer {
  height: 95px;
  width: 100%;
  position: absolute;
  background: #2B2B39;
}
@font-face {
  font-family: Ageo-Regular;
  src: url("../fonts/Ageo-Regular.otf") format("opentype");
}
@font-face {
  font-family: Ageo-ExtraBold;
  src: url("../fonts/Ageo-ExtraBold.otf") format("opentype");
}
@font-face {
  font-family: Ageo-Bold;
  src: url("../fonts/Ageo-Bold.otf") format("opentype");
}
@font-face {
  font-family: Ageo-SemiBold;
  src: url("../fonts/Ageo-SemiBold.otf") format("opentype");
}
</style>
