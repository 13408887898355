<template>
<div>
    <b-container v-for="data in work" :key="data.title">
        <b-card class="work-experience-card shadow-lg p-3 mb-5 bg-white" :title="data.title" :sub-title="data.date">
            <b-card-text class="card-text">
                <p>{{data.description}}</p> 
                <ul v-for="desArr in data.descriptionArr" :key="desArr">
                    <li>{{desArr}}</li>
                </ul>
            </b-card-text>

            <b-row>
              <b-col cols="10">
                <a :href="data.website" target="_blank"><b-button size="sm" class="company-button" type="submit">Company Website</b-button></a>
              </b-col>
              <b-col cols="1" style="margin-right: 10px;">
                <img class="company-icons" v-if="data.companyIcons" :src="data.companyIcons" alt=""/>
              </b-col>
            </b-row>
        </b-card>
    </b-container>

</div>

</template>

<script>
import work from '../data/work-experience.json'
export default {
    name: 'Experience',
    data(){
        return{
            work: work.work
        }
    }
}
</script>

<style scoped>
h1 {
  font-size: 80px;
  font-family: Ageo-ExtraBold;
}
h2 {
  font-size: 40px;
  font-family: Ageo-ExtraBold;
  color: #323345;
}
h3 {
  font-size: 30px;
  font-family: Ageo-ExtraBold;
  color: #323345;
}
h6 {
  font-size: 25px;
  font-family: Ageo-Regular;
  color: #323345;
}
#app {
  background-image: url('~@/assets/1920p.png');
  background-size: 100%;
  height: 100%;
  width: 100%;
}
.nav-bar {
  padding-top: 70px;
}
.nav-item {
  padding-right: 50px;
  font-family: Ageo-SemiBold;
  font-size: 18px;
}
.nav-button {
  border-radius: 30px;
  width: 180px;
  height: 45px;
  background-color: #323345;
  font-family: Ageo-Bold;
  font-size: 20px;
}
.title {
  margin-top: 100px;
  color: #323345;
}
.arrow-down {
  margin-top: 40px;
}
#about-me {
  margin-top: 120px;
  padding-top: 50px;
}
.profile-pic {
  border-radius:50% 50% 50% 50%;  
  width:400px;
  height:400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}
.company-icons {
  height: 45px;
  width: 45px;
  border-radius: 10px;
}
.paragraph {
  margin-top: 50px;
  margin-bottom: 70px;
  line-height: 1.8;
}
.social-media {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.sections {
  margin-bottom: 80px;
}
.title-skill {
  line-height: 1.8;
  margin-top: 30px;
  margin-bottom: 10px;
}
.skill {
  line-height: 1.8;
  margin-bottom: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.work-experience-card {
  border-radius: 40px;
}
.company-button {
  border-radius: 30px;
  width: 200px;
  height: 45px;
  background-color: #323345;
  font-family: Ageo-Bold;
  font-size: 20px;
}
.card-text {
  line-height: 1.8;
  margin-top: 30px;
  margin-bottom: 30px;
}
#experience {
  margin-top: 50px;
  padding-top: 100px;
}
#portfolio {
  padding-top: 50px;
  margin-top: 100px;
  margin-bottom: 100px;
}
.portfolio-card {
  height: 450px;
  border-radius: 40px;
  margin-bottom: 40px;
}
.project-image {
  max-width: 750px;
  height: 450px;
}
.project-body {
  margin-top: 35px;
  margin-bottom: 35px;
}
.toolkit {
  color: rgb(134, 134, 134);
}
.project-button {
  border-radius: 30px;
  width: 170px;
  height: 45px;
  background-color: #323345;
  font-family: Ageo-Bold;
  font-size: 20px;
  margin-right: 15px;
}
@font-face {
  font-family: Ageo-Regular;
  src: url("../fonts/Ageo-Regular.otf") format("opentype");
}
@font-face {
  font-family: Ageo-ExtraBold;
  src: url("../fonts/Ageo-ExtraBold.otf") format("opentype");
}
@font-face {
  font-family: Ageo-Bold;
  src: url("../fonts/Ageo-Bold.otf") format("opentype");
}
@font-face {
  font-family: Ageo-SemiBold;
  src: url("../fonts/Ageo-SemiBold.otf") format("opentype");
}
</style>
